const paramsError = new Error('Params missing');

export default {
  computed: {
    isWorker() {
      return this.$store.getters['auth/this.isWorker'];
    },

    isOwner() {
      return this.$store.getters['auth/isOwner'];
    },
  },
  methods: {
    // TEST
    testNew(action) {
      throw paramsError;
    },

    // MESSAGE:NEW
    messageNew(action) {
      const { conversation_id } = action;
      if (conversation_id) {
        return `/messages?id=${conversation_id}`;
      }
      return paramsError;
    },

    // TIMESHEET:MESSAGE
    timesheetMessage(action) {
      const { worker_slug, timesheet_id } = action;

      if (this.isWorker && timesheet_id) {
        return `/timesheets/${timesheet_id}`;
      }

      if (!this.isWorker && worker_slug && timesheet_id) {
        return `/workers/${worker_slug}/timesheets/${timesheet_id}`;
      }

      return paramsError;
    },

    // TIMESHEET:SUBMITTED
    timesheetSubmitted(action) {
      const { worker_slug, timesheet_id } = action;

      if (this.isWorker && timesheet_id) {
        return `/timesheets/${timesheet_id}`;
      }

      if (!this.isWorker && worker_slug && timesheet_id) {
        return `/workers/${worker_slug}/timesheets/${timesheet_id}`;
      }

      return paramsError;
    },

    // TIMESHEET:REJECTED
    timesheetRejected(action) {
      const { timesheet_id } = action;
      if (timesheet_id) {
        return `/timesheets/${timesheet_id}`;
      }
      return paramsError;
    },

    // TIMESHEET:ACCEPTED
    timesheetAccepted(action) {
      const { timesheet_id } = action;

      if (timesheet_id) {
        return `/timesheets/${timesheet_id}`;
      }

      return paramsError;
    },

    // TIMESHEET:READY_FOR_PAY
    timesheetReady_for_pay(action) {
      const { timesheet_id } = action;

      if (timesheet_id) {
        return `/timesheets/${timesheet_id}`;
      }

      return paramsError;
    },
    // CARD:NEED_CHECK
    cardNeed_check(action) {
      
      const { user_slug } = action;

      return `/card?user=${user_slug}`;
    },

    // WORKER:NEW
    workerNew(action) {
      const { worker_slug } = action;
      if (worker_slug) {
        return `/workers/${worker_slug}`;
      }
      return paramsError;
    },

    // JOBOFFER:NEW
    job_offerNew(action) {
      const { conversation_id } = action;
      if (conversation_id) {
        return `/messages?id=${conversation_id}`;
      }
      return paramsError;
    },

    // JOBOFFER:ACCEPTED
    job_offerAccepted(action) {
      const { worker_slug } = action;
      if (worker_slug) {
        return `/workers/${worker_slug}`;
      }
      return paramsError;
    },

    // JOBPOST:APPLY
    job_postApply(action) {
      const { conversation_id } = action;
      if (conversation_id) {
        return `/messages?id=${conversation_id}`;
      }
      return paramsError;
    },

    // ASSIGNMENT:NEW
    assignmentNew(action) {
      const { worker_slug, assignment_id } = action;

      if (assignment_id) {
        return `${
          worker_slug ? '/workers/' + worker_slug : ''
        }/assignments/${assignment_id}`;
      }
      return paramsError;
    },

    // ASSIGNMENT:WORKERNEW
    assignmentWorkerNew(action) {
      const { assignment_id } = action;

      if (assignment_id) {
        return `/assignments/${assignment_id}`;
      }
      return paramsError;
    },

    // ASSIGNMENT:TERMINATED
    assignmentTerminated(action) {
      const { worker_slug, assignment_id } = action;

      if (assignment_id) {
        return `${
          worker_slug ? '/workers/' + worker_slug : ''
        }/assignments/${assignment_id}`;
      }
      return paramsError;
    },

    // CONTRACT MANAGER:NEW
    contractManagerNew(action) {
      const { worker_slug, contract_id } = action;
      if (contract_id) {
        return `${
          worker_slug ? '/contracts/' + worker_slug : ''
        }/${contract_id}`;
      }
      return paramsError;
    },

    // CONTRACT WORKER:NEW
    contractWorkerNew(action) {
      const { contract_id } = action;

      if (contract_id) {
        return `/contracts/${contract_id}`;
      }
      return paramsError;
    },

    // ASSIGNMENT:EXTENDED
    assignmentExtended(action) {
      const { worker_slug, assignment_id } = action;

      if (assignment_id) {
        return `${
          worker_slug ? '/workers/' + worker_slug : ''
        }/assignments/${assignment_id}`;
      }
      return paramsError;
    },

    // ASSIGNMENT:EXPIRING
    assignmentExpiring(action) {
      const { worker_slug, assignment_id } = action;

      if (assignment_id) {
        return `${
          worker_slug ? '/workers/' + worker_slug : ''
        }/assignments/${assignment_id}`;
      }
      return paramsError;
    },

    // ASSIGNMENT:RAMSUPDATED
    assignmentRams_updated(action) {
      const { assignment_id } = action;

      if (assignment_id) {
        return `/assignments/${assignment_id}`;
      }
      return paramsError;
    },

    // CONTRACT:EXTENDED
    contractExtended(action) {
      const { worker_slug } = action;

      if (this.isWorker) {
        throw new Error('no route for worker role');
        // return false // ?
      }

      if (!this.isWorker && worker_slug) {
        return `/workers/${worker_slug}`;
      }
      return paramsError;
    },

    // CONTRACT:EXPIRING
    contractExpiring(action) {
      const { worker_slug } = action;

      if (this.isWorker) {
        throw new Error('no route for worker role');
        // return false // ?
      }

      if (!this.isWorker && worker_slug) {
        return `/workers/${worker_slug}`;
      }
      return paramsError;
    },

    // CONTRACT:TERMINATED
    contractTerminated(action) {
      const { worker_slug } = action;

      if (this.isWorker) {
        throw new Error('no route for worker role');
        // return false // ?
      }

      if (!this.isWorker && worker_slug) {
        return `/workers/${worker_slug}`;
      }
      return paramsError;
    },

    // CONTRACT:HOURLYRATE
    contractHourly_rate(action) {
      const { worker_slug } = action;

      if (this.isWorker) {
        throw new Error('no route for worker role');
        // return false // ?
      }

      if (!this.isWorker && worker_slug) {
        return `/workers/${worker_slug}`;
      }
      return paramsError;
    },
  },
};
