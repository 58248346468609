import {EventBus}              from "@/plugins/event-bus";
import debuglog                from "@/helpers/debuglog.js";
import {isDebug, isInWebView, webViewCanRenewTokens}  from "@/helpers/environment";
import {delay}                 from "@/helpers/helpers";
import {SKILL_CARD_SIDE_FRONT} from "~/components/WorkerRegistration/constants.ts";
import LocalStorage from "@/helpers/local-storage";
import Vue          from 'vue'

export const ReactNativeMessages = {
  /** dual **/
  NEW_AUTH_TOKEN_RECEIVED: 'new-auth-token-received',


  /** messages to parent **/
  REQUEST_NEW_AUTH_TOKEN: 'request-new-auth-token',
  START_ONFIDO: 'start-onfido',
  START_SKILL_CARD_SINGLE_SIDE_CAPTURE: 'start-skill-card-single-side-capture',
  START_PROFILE_PHOTO_CAPTURE: 'start-profile-photo-capture',
  START_SECURITY_GUARD_QR_CODE_SCAN: 'start-security-guard-qr-code-scan',
  START_LOGOUT_PROCESS: 'start-logout-process',
  // RN should show notifications permission request dialog upon receiving this message
  ASK_FOR_NOTIFICATIONS_PERMISSION: 'ask-for-notifications-permission',
  // Sent before showing 'notifications page' to make sure user have/doesnt have permissions. RN should reply with calling
  GET_NOTIFICATIONS_PERMISSION_CURRENT_STATUS: 'get-notifications-permission-current-status',
  GET_DEVICE_ID: 'get-device-id',

  REGISTRATION_PROCESS_COMPLETE: 'registration-process-complete',
  CONSOLE_LOG: 'console-log',
  RAM_PDF_OPEN: 'ram-pdf-open',

  /** messages from parent **/
  NOTIFICATIONS_PERMISSION_REQUEST_RESULT: 'notifications-permission-request-result',
  NOTIFICATIONS_PERMISSION_CURRENT_STATUS_RESULT: 'notifications-permission-current-status-result',
  ONFIDO_PROCESS_FINISHED: 'onfido-process-finished',
  SKILL_CARD_SINGLE_SIDE_CAPTURE_FINISHED: 'skill-card-single-side-capture-finished',
  PROFILE_PHOTO_CAPTURE_FINISHED: 'profile-photo-capture-finished',
  SECURITY_GUARD_QR_CODE_SCAN_FINISHED: 'security-guard-qr-code-scan-finished',
  DEVICE_ID_RESULT: 'device-id-result',
  LOGOUT_PROCESS_FINISHED: 'logout-process-finished',
  RAM_PDF_CLOSE: 'ram-pdf-close',
}

function getEventNameForReactNativeMessageCode(code) {
  return 'reactNativeMessageFromParent_' + code;
}

const ReactNativeChannelMixin = {
  methods: {
    isInWebView() {
      return isInWebView()
    },
    // https://github.com/react-native-community/react-native-webview/blob/master/docs/Guide.md#communicating-between-js-and-native
    webViewPostMessage(messageCode, data) {
      const messageData = {
        code: messageCode,
        data: data
      };
      /** detect infinite loop **/
      const isSendingConsoleLogToParent = messageCode === ReactNativeMessages.CONSOLE_LOG;
      const msg = JSON.stringify(messageData);
      const logSafe = (...log) => {
        if (isSendingConsoleLogToParent) {
          console._originalDebug.apply(console, log)
        } else {
          debuglog(...log);
        }
      }
      logSafe("[WebView-->ReactNative] Sending message to React Native", messageData);
      if (!window.ReactNativeWebView) {
        if (isDebug() && window._mockReactNativeWebView) {
          logSafe("ReactNativeWebView not available, using fake mockup ReactNativeWebView object");
          window._mockReactNativeWebView.postMessage(msg);
          return;
        }
        logSafe("Sending event to react native failed! window.ReactNativeWebView is null");
        return;
      }

      /*if (!isSendingConsoleLogToParent) {
        console.log(`ReactNativeWebView.PostMessage(${msg})`)
      }*/
      window.ReactNativeWebView.postMessage(msg)
    },
    webViewListenOnce(listenToCode, eventListener) {
      const listenerWrapped = async ({code, data}) => {
        switch (code) {
          case listenToCode:
            eventListener(data);
            // debuglog("webView listener fired, current list of listeners: ", EventBus._events);
            break;
        }
      }
      EventBus.$once(getEventNameForReactNativeMessageCode(listenToCode), listenerWrapped);
      // debuglog("Installed new webView listener, current list of listeners: ", listenToCode, "=>", EventBus._events);
    },
    webViewListen(listenToCode, eventListener) {
      const listenerWrapped = async ({code, data}) => {
        switch (code) {
          case listenToCode:
            eventListener(data);
            // debuglog("webView listener fired, current list of listeners: ", EventBus._events);
            break;
        }
      }
      EventBus.$on(getEventNameForReactNativeMessageCode(listenToCode), listenerWrapped);
    },
    
    webViewListenGuardToken(listenToCode, eventListener) {
      const listenerWrapped = async ({code, data}) => {
        switch (code) {
          case listenToCode:
            eventListener(data);
            // debuglog("webView listener fired, current list of listeners: ", EventBus._events);
            break;
        }
      }
      EventBus.$on(getEventNameForReactNativeMessageCode(listenToCode), listenerWrapped);
    },
    webViewListenerRemove(listenToCode) {
      EventBus.$off(getEventNameForReactNativeMessageCode(listenToCode));
    },
    webViewPostAskForNotificationsPermission() {
      this.webViewPostMessage(ReactNativeMessages.ASK_FOR_NOTIFICATIONS_PERMISSION);
    },
    webViewRefreshToken() {      

      return new Promise( (resolve, reject) => {

          if (!webViewCanRenewTokens()) {
            reject("This webview can not renew tokens, check webViewCanRenewTokens before calling!")
          }

          this.webViewListenOnce(ReactNativeMessages.NEW_AUTH_TOKEN_RECEIVED, ({token: newToken}) => {
          console.log(`Parent Replied With New Token (${window.location}): ${newToken}`)
          LocalStorage.setTokenWithoutNotify(newToken)
          resolve(newToken)
        })

        //Request parent renew token...
        console.log(`Requesting Parent Provide New Token (${window.location}), Current: ${LocalStorage.getToken()}`)
        this.webViewPostMessage(ReactNativeMessages.REQUEST_NEW_AUTH_TOKEN, {token: LocalStorage.getToken()})
        
      })

    }
  }
}
export default ReactNativeChannelMixin;

export const ReactNativeChannel = new Vue ({
  mixins: [ReactNativeChannelMixin]
})

function isValidReactNativeMessageCode(code) {
  return Object.values(ReactNativeMessages).indexOf(code) >= 0;
}

window.reactNativePostMessageFromParent = function (content) {
  const decoded = JSON.parse(content);
  debuglog("[WebView<--ReactNative] Got new message from React Native", decoded);
  const code = decoded.code;

  if (!isValidReactNativeMessageCode(code)) {
    throw new Error("Received invalid ReactNative Message Code from parent: " + code);
  }
  EventBus.$emit('reactNativeMessageFromParent', decoded);

  // In order to be able to use Vue.$once we must use custom events for each code
  EventBus.$emit(getEventNameForReactNativeMessageCode(code), decoded);
}

// For debugging purposes:
if (isDebug()) {
  window.__EventBus = EventBus;

  window.simulatePostMessageFromParent = function (code, data) {
    window.reactNativePostMessageFromParent(JSON.stringify({code, data}))
  }
  window.simulateNotificationsRequestMessage = function (wasSuccess) {
    const message = {
      code: ReactNativeMessages.NOTIFICATIONS_PERMISSION_REQUEST_RESULT,
      data: {
        result: wasSuccess
      }
    };
    window.reactNativePostMessageFromParent(JSON.stringify(message))
  }

  window.simulateOnfidoFinished = function () {
    const message = {
      code: ReactNativeMessages.ONFIDO_PROCESS_FINISHED,
      data: {}
    }
    window.reactNativePostMessageFromParent(JSON.stringify(message))
  }
  if (isDebug() && !isInWebView()) {
    const frontDemo = require('!!raw-loader!@/demo-image-skill-card-front-base64.txt').default;
    const backDemo = require('!!raw-loader!@/demo-image-skill-card-back-base64.txt').default;
    let mockupLastAuthToken;
    window._mockReactNativeWebView = {
      async postMessage(msg) {
        const decoded = JSON.parse(msg);
        await delay(100);
        switch (decoded.code) {
          case ReactNativeMessages.GET_NOTIFICATIONS_PERMISSION_CURRENT_STATUS:
            setTimeout(() => window.reactNativePostMessageFromParent(
              JSON.stringify({
                               code: ReactNativeMessages.NOTIFICATIONS_PERMISSION_CURRENT_STATUS_RESULT,
                               data: {
                                 result:
                                   true
                                 // Math.random() > 0.5
                               }
                             })
            ), 0);
            break;
          case ReactNativeMessages.ASK_FOR_NOTIFICATIONS_PERMISSION:
            alert("User is displayed with notification permissions dialog");
            setTimeout(() => window.reactNativePostMessageFromParent(
              JSON.stringify({code: ReactNativeMessages.NOTIFICATIONS_PERMISSION_REQUEST_RESULT, data: {result: true}})
            ), 0);
            break;
          case ReactNativeMessages.REGISTRATION_PROCESS_COMPLETE:
            alert(
              "ReactNative has been notified about completed registration. It will now close the webview and load user panel");
            break;
          case ReactNativeMessages.START_ONFIDO:
            alert(
              "ReactNative has been notified to open Onfido Flow on it's part. After taking the photos webview is shown again");
            setTimeout(() => window.reactNativePostMessageFromParent(
              JSON.stringify({code: ReactNativeMessages.ONFIDO_PROCESS_FINISHED, data: {result: true}})
            ), 0);
            break;
          case ReactNativeMessages.START_SKILL_CARD_SINGLE_SIDE_CAPTURE:
            alert(
              "ReactNative has been notified to open Camera to capture single side of Skill Card");
            setTimeout(() => window.reactNativePostMessageFromParent(
              JSON.stringify({
                               code: ReactNativeMessages.SKILL_CARD_SINGLE_SIDE_CAPTURE_FINISHED, data: {
                  base64: decoded.data.side === SKILL_CARD_SIDE_FRONT ? frontDemo : backDemo
                }
                             })
            ), 0);
            break;
          case ReactNativeMessages.START_PROFILE_PHOTO_CAPTURE:
            alert(
              "ReactNative has been notified to open Camera to capture profile photo");
            setTimeout(() => window.reactNativePostMessageFromParent(
              JSON.stringify(
                {
                  code: ReactNativeMessages.PROFILE_PHOTO_CAPTURE_FINISHED,
                  data: {base64: frontDemo}
                })
            ), 0);
            break;
          case ReactNativeMessages.START_SECURITY_GUARD_QR_CODE_SCAN:
            alert(
              "ReactNative has been notified to open camera to scan QR code");
            setTimeout(() => window.reactNativePostMessageFromParent(
              JSON.stringify(
                {
                  code: ReactNativeMessages.SECURITY_GUARD_QR_CODE_SCAN_FINISHED,
                })
            ), 0);
            break;
          case ReactNativeMessages.GET_DEVICE_ID:
            if (!window.localStorage.getItem('debug_device_id')) {
              window.localStorage.setItem('debug_device_id', Math.random());
            }
            setTimeout(() => window.reactNativePostMessageFromParent(
              JSON.stringify(
                {
                  code: ReactNativeMessages.DEVICE_ID_RESULT,
                  data: {
                    device_id: window.localStorage.getItem('debug_device_id')
                  }
                })
            ), 0);
            break;
          case ReactNativeMessages.NEW_AUTH_TOKEN_RECEIVED:
            if (mockupLastAuthToken !== decoded.data.token) {
              mockupLastAuthToken = decoded.data.token;
            }
            setTimeout(() => window.reactNativePostMessageFromParent(
              JSON.stringify(
                {
                  code: ReactNativeMessages.NEW_AUTH_TOKEN_RECEIVED,
                  data: {
                    token: mockupLastAuthToken
                  }
                })
            ), 0);
            break;
          case ReactNativeMessages.START_LOGOUT_PROCESS:
            alert(
              "ReactNative has been notified to logout user");
            setTimeout(() => window.reactNativePostMessageFromParent(
              JSON.stringify(
                {
                  code: ReactNativeMessages.LOGOUT_PROCESS_FINISHED,
                })
            ), 0);
            break;
        }
      }
    }
  }
}
