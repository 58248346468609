import jwt          from 'jsonwebtoken';
import {
  getMobileWorkerRegisterFlowPath, isInWebView,
  isMobileWorkerRegisterFlow,
  redirectToMobileWorkerRegisterFlow
}                   from "@/helpers/environment";
import LocalStorage from "@/helpers/local-storage";
import debuglog     from "@/helpers/debuglog";

const STATUS_COMPLETED = 'COMPLETED';

const ROLE_WORKER = 'WORKER';
const ROLE_OWNER = 'OWNER';
const ROLE_MANAGER = 'MANAGER';
export default function ({store, route, redirect}) {
  const doMiddleware = async () => {
    const loginPath = '/login';

    //console.log(`----->doMiddleware-enter: ${route.path}`)
    if (route.query.token) {
      //console.log(`doMiddleware-setToken: `)
      LocalStorage.setTokenWithoutNotify(route.query.token);
    }

    const token = LocalStorage.getToken()
    const decoded = jwt.decode(token);

    const authFailedRedirect = (path) => {
      //console.log(`doMiddleware-EXIT: ${path}`)
      // Whatever we do, we never want to show anything besides register flow while in web view`
      if (
        isInWebView() ||
        isMobileWorkerRegisterFlow(route)) {
        return redirectToMobileWorkerRegisterFlow({redirect});
      } else {
        return redirect(path);
      }
    }

    if (0 === route.path.indexOf('/verify-email')
      || 0 === route.path.indexOf('/register/worker')
      || 0 === route.path.indexOf('/login')
      || 0 === route.path.indexOf('/invite')
      || 0 === route.path.indexOf('/visitors/invite')
      || 0 === route.path.indexOf('/visitors/qr')
      || 0 === route.path.indexOf('/visitor/qr')
      || 0 === route.path.indexOf('/generate-qr')
      || 0 === route.path.indexOf('/visitor-invite/')
      || isMobileWorkerRegisterFlow(route)
    ) {
      // do nothing
      //console.log(`doMiddleware-pathChecks1: `)
    } else if (!token) {
      //console.log(`doMiddleware-!token: `)
      if (-1 === route.path.indexOf(loginPath)) {
        return authFailedRedirect(loginPath);
      }
    } else {
      // update store with user basic info from token

      //console.log(`doMiddleware-auth/getUser1: `)
      let user = store.getters['auth/getUser']
      if (!user || user.id != decoded.usr.uid || user.status != decoded.usr.sts) {
        //console.log(`doMiddleware-auth/getUserByToken:`, decoded)
        await store.dispatch('auth/getUserByToken', decoded)
        user = store.getters['auth/getUser']
        //console.log(`doMiddleware-auth/getUser2:`, user)
      }

      const company = store.getters['companies/getCompany']
      //console.log(`doMiddleware-companies/getCompany:`, company)

      if (0 === route.path.indexOf('/register')) {
        // if completed user is trying to access register route redirect to dashboard
        // force manages to skip registration
        if (
          route.path !== '/register/owner/thank-you' &&
          ((ROLE_WORKER === user.default_role && STATUS_COMPLETED === user.status)
            || (ROLE_OWNER === user.default_role && STATUS_COMPLETED === user.status)
            || (ROLE_MANAGER === user.default_role))
        ) {
          //console.log(`doMiddleware-accessRegisterRouteRedirect:`)
          return authFailedRedirect('/')

        } else {
          // force incomplete user in registration to stick to his registration route
          if (null === route.name || (
            (-1 === route.path.indexOf('/register/' + user.default_role.toLowerCase()))
            && (-1 === route.path.indexOf('/register/old-' + user.default_role.toLowerCase()))
          )) {
            //console.log(`doMiddleware-forOnRegisterRoute:`)
            if (ROLE_WORKER === user.default_role) {
              return redirectToMobileWorkerRegisterFlow({redirect})
            } else if (ROLE_OWNER === user.default_role) {
              return authFailedRedirect('/register/owner')
            }
          }
        }

      } else if (-1 === route.path.indexOf(loginPath)) {
        //console.log(`doMiddleware-notOnLoginPath:`, user)
        if (!user) {
          //console.log(`doMiddleware-notOnLoginPath:`)
          return authFailedRedirect(loginPath)
        }

        // prevent incomplete user from accessing other routes then register and login
        if (ROLE_WORKER === user.default_role && STATUS_COMPLETED !== user.status) {
          //console.log(`doMiddleware-notOnLoginPath-userNotCompleted:`, user)
          //          if (route.path.indexOf('/more') !== 0) {
            debuglog(`[AUTHREDIRECT] user status != COMPLETED`, {user})
            return authFailedRedirect('/register/worker')  
//          }
        } else if (ROLE_OWNER === user.default_role && STATUS_COMPLETED !== user.status && user.invite_hash) {
          //console.log(`doMiddleware-notOnLoginPath-ownerNotCompleted:`, user.invite_hash)
          return authFailedRedirect('/invite/'+user.invite_hash+'/details')
        }
        else if (ROLE_OWNER === user.default_role && STATUS_COMPLETED !== user.status) {
          //console.log(`doMiddleware-notOnLoginPath-ownerNotCompleted: `)
          return authFailedRedirect('/register/owner')
        }
      }

      // Autologin for 'Remember me' option.
      if (token && route.path === loginPath) {
        //console.log(`doMiddleware-autoLogin: `)
        return authFailedRedirect('/')
      }

      if (ROLE_MANAGER === user.default_role && (route.path.startsWith('/contracts/') || route.path === '/contracts')) {
        //console.log(`doMiddleware-noAccessContractsForManagers: `)
        return authFailedRedirect('/')
      }
      //console.log(`doMiddleware-EXIT: `)
    }
  }
  return new Promise(async (resolve) => {
    const url = await doMiddleware();
    resolve(url)
  });
}
