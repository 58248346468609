// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/password-hint.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-form[data-v-8454560c]{margin:0 auto;width:388px}@media (max-width:767px){.login-form[data-v-8454560c]{margin-bottom:0;margin-top:55px;width:325px}}.login-title[data-v-8454560c]{color:#141414;font-family:\"Work Sans\",“HelveticaNeue-Light”,“Helvetica Neue Light”,“Helvetica Neue”,Helvetica,Arial,“Lucida Grande”,sans-serif;font-size:82px;font-weight:600;letter-spacing:-2px;margin-bottom:75px;text-align:center}@media (max-width:767px){.login-title[data-v-8454560c]{font-size:62px;margin-bottom:20px}}.login-button[data-v-8454560c]{margin-bottom:20px;text-transform:uppercase;width:100%}.login-options[data-v-8454560c]{display:flex;flex-direction:row;font-size:.75rem;justify-content:space-between;margin:.875rem 0;-webkit-text-decoration:underline;text-decoration:underline}.login-options a[data-v-8454560c]:hover{color:#141414}.login-input-password[data-v-8454560c]{position:relative}.login-input-password .icon[data-v-8454560c]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:1.25rem;content:\"\";cursor:pointer;height:55px;position:absolute;right:0;top:calc(100% - 55px);width:3rem}.ui-textbox__input[data-v-8454560c]{padding:1rem 0 1rem 3rem}.email-verification[data-v-8454560c]{color:red;font-size:14px;margin:1rem 0;text-align:center}.email-request[data-v-8454560c]{font-weight:700;-webkit-text-decoration:underline;text-decoration:underline}.test-buttons[data-v-8454560c]{bottom:0;flex-wrap:nowrap;left:0;max-width:100%;overflow-x:auto;padding:20px;position:fixed;right:0;text-align:center;white-space:nowrap;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
