<template>
  <div class="login-form">
    <h1 class="login-title">Sign in</h1>
    <div class="login-input-login">
      <UiTextbox
        v-model="login"
        placeholder="Email / Phone number"
        @keydown.enter="handleLogin"
        type="text"
      >
        Email / Phone number
      </UiTextbox>
    </div>

    <div class="login-input-password">
      <UiTextbox
        class="login-input-password"
        v-model="password"
        placeholder="Password"
        @keydown.enter="handleLogin"
        :type="passwordInputType"
      >
        Password
      </UiTextbox>
    </div>

    <div class="login-options">
      <UiCheckbox v-model="remember">
        Remember me
      </UiCheckbox>

      <nuxt-link to="/login/password/forgot">
        Forgot password?
      </nuxt-link>
    </div>

    <UiButton @click="handleLogin" class="login-button">Sign in</UiButton>

    <div v-show="authError" class="text-center alert px-0">
      {{ loginError }}
    </div>

    <div class="test-buttons" v-if="checkTest()">
      <UiButton @click="handleUser('owner')" size="small" color="red"
      >Main Contarctor<br/>Owner
      </UiButton>
      <UiButton @click="handleUser('manager')" size="small" color="red"
      >Main Contractor<br/>Manager
      </UiButton>
      <UiButton @click="handleUser('worker')" size="small" color="red"
      >Main Contractor<br/>Worker
      </UiButton>
      <UiButton @click="handleUser('security')" size="small" color="green"
      >Main Contractor<br/>Security Guard
      </UiButton>
      <br /><br />
      <UiButton @click="handleUser('subowner')" size="small" color="orange"
      >Sub Company<br/>Owner
      </UiButton>
      <UiButton @click="handleUser('submanager')" size="small" color="orange"
      >Sub Company<br/>Manager
      </UiButton>
      <UiButton @click="handleUser('subworker')" size="small" color="orange"
      >Sub Company<br/>Worker
      </UiButton>
      <UiButton @click="handleUser('anyworker')" size="small" color="primary"
      >Any <br />Worker
      </UiButton>
    </div>
  </div>
</template>

<script>
import {isTestDebug} from "@/helpers/environment";

export default {
  name: 'Login',
  data() {
    return {
      login: '',
      password: '',
      field: 'email',
      passwordInputType: 'password',
      remember: false,
    };
  },
  computed: {
    authError() {
      return this.$store.getters['auth/getError'];
    },

    emailNotVerified() {
      return (
        this.authError &&
        this.authError.response &&
        this.authError.response.data &&
        this.authError.response.data.message &&
        '#EMAILNOTVERIFIED#' === this.authError.response.data.message
      );
    },

    loginError() {
      return this.authError &&
      this.authError.response &&
      this.authError.response.data &&
      this.authError.response.data.message &&
      '#EMAILNOTVERIFIED#' !== this.authError.response.data.message
        ? this.authError.response.data.message
        : '';
    },
  },
  methods: {
    handleLogin() {
      if (this.login && this.password) {
        const credentials = {
          [this.field]: this.login,
          password: this.password,
          remember: this.remember,
        };

        this.$store.dispatch('auth/login', credentials);
      }
    },

    checkTest() {
      return isTestDebug()
    },

    handleUser(user) {
      this.field = 'email'
      switch (user) {
        case 'owner':
          this.login = 'owner@dev.mc';
          this.password = 'True3.14';
          break;
        case 'manager':
          this.login = 'manager@dev.mc';
          this.password = 'True3.14';
          break;
        case 'worker':
          this.field = 'phone_number'
          this.login = '+44 100100101';
          this.password = 'True3.14';
          break;
        case 'security':
          this.field = 'phone_number'
          this.login = '+44 100100103';
          this.password = 'True3.14';
          break;
        case 'subowner':
          this.login = 'sub@dev.mc';
          this.password = 'True3.14';
          break;
        case 'submanager':
          this.login = 'sub-manager@dev.mc';
          this.password = 'True3.14';
          break;
        case 'subworker':
          this.field = 'phone_number'
          this.login = '+44 200200201';
          this.password = 'True3.14';
          break;
        case 'anyworker':
          this.field = 'phone_number'
          this.login = '+44 ';
          this.password = '';
          break;
      }

      this.handleLogin();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/global_vars';

.login {
  &-form {
    margin: 0 auto;
    width: 388px;

    @media (max-width: $mobileWidth) {
      width: 325px;
      margin-top: 55px;
      margin-bottom: 0;
    }
  }

  &-title {
    font-size: 82px;
    font-weight: $fontBolder;
    color: #141414;
    text-align: center;
    letter-spacing: -2px;
    font-family: $fontHeader;
    margin-bottom: 75px;

    @media (max-width: $mobileWidth) {
      font-size: 62px;
      margin-bottom: 20px;
    }
  }

  &-button {
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: underline;
    font-size: 0.75rem;
    //margin: .875rem 0 2.25rem;
    margin: 0.875rem 0;

    a:hover {
      color: #141414;
    }
  }

  &-input {
    &-password {
      position: relative;

      .icon {
        content: '';
        background-image: url('../assets/images/password-hint.svg');
        background-position: center;
        background-size: 1.25rem;
        background-repeat: no-repeat;
        position: absolute;
        width: 3rem;
        top: calc(100% - #{$inputHeight});
        height: $inputHeight;
        right: 0;
        cursor: pointer;
      }
    }
  }
}

.ui-textbox__input {
  padding: 1rem 0 1rem 3rem;
}

.email {
  &-verification {
    font-size: 14px;
    color: red;
    margin: 1rem 0;
    text-align: center;
  }

  &-request {
    font-weight: bold;
    text-decoration: underline;
  }
}

.test-buttons {
  position: fixed;
  white-space: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
  right: 0;
  padding: 20px;
  overflow-x: auto;
}
</style>
