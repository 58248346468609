<template>
  <span class="info-popup">
    <img class="info-popup__icon" src="~/assets/images/info.svg" alt="i" @click="clickIt" :class="{'info-popup-icon-disabled': disabled}"/>
    <UiTooltip position="top" :tooltipClass="tooltipClass" v-if="!disabled">
      <slot></slot>
    </UiTooltip>
  </span>
</template>

<script>
export default {
  name: 'InfoPopup',
  props: ['tooltipClass', 'disabled'],

  methods: {
    clickIt() {
      this.$emit('click')
    }
  }
};
</script>

<style lang="scss">
@import '../assets/scss/global_vars';

.info-popup {
  &__icon {
    max-width: 16px;
    &.info-popup-icon-disabled {
      opacity: 0.3;
    }
  }
}
</style>
