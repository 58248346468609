import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
    async fetch ({commit}, payload) {
        try {
            const result = await this.$axios.get('api/me/workers/' + payload.slug);
            commit('setWorker', result.data.data);
        } catch (error) {
            commit('setError', error);
        }
    },
    async updateProfile({commit}, payload) {
        enableLoader()
        try {
            if (payload.data.editable) {
                const result = await this.$axios({
                    method: 'put',
                    url: '/api/me/workers/' + payload.data.id + '/profile-update',
                    data: payload.data,
                  }).then( res =>{
                    this.$router.back();
                }).catch((err)=>{
                    commit('setError', err);
                });
            } else {
                EventBus.$emit('createSnackbar', {
                    message: `Can't update worker profile. Make sure, that worker belongs to your company`,
                });
            }
        } catch (error) {
            commit('setError', error);
        }
        disableLoader()
    }
}