export default {
    getManagerInfo: state => {
        return state.userInfo && state.userInfo.manager ?
        state.userInfo.manager : {};
    },
    getSiteInfo: state => {
        return state.siteInfo;
    },
    getSitePeople: state => {
        return state.workers && state.workers.length ? {
            workers: state.workers
        } : {};
    },
    getUserHash: state => {
        return state.userInfo && state.userInfo.hash ?
        state.userInfo.hash : null;
    },
    getUserInfo: state => {
        return state.userInfo && state.userInfo.worker ?
        state.userInfo.worker : {};
    }

}
