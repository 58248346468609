export default {
  setWorkers: (state, workers) => {
    state.workers = workers;
  },
  addWorkers: (state, workers) => {
    state.workers = state.workers.concat(workers);
  },

  setWorker: (state, worker) => {
    if (null === worker) {
      state.worker = null;
      return;
    }
    worker.dictMap = {};

    worker.dictionary.forEach(dict => {
      if (!worker.dictMap.hasOwnProperty(dict.type)) {
        worker.dictMap[dict.type] = [];
      }

      worker.dictMap[dict.type].push(dict.value);
    });

    worker.documents_names = [];
    (worker.documents || []).forEach(doc => {
      worker.documents_names.push(doc.custom_properties.name);
    });

    state.worker = worker;
  },
  setLinks: (state, links) => {
    state.links = links;
  },

  setMeta: (state, meta) => {
    state.meta = meta;
  },

  toggleData: state => {
    state.hasData = true;
  },
};
