
import cscs_cards from "~/etc/cscs-cards.json"
//const cscs_card_images = require.context('~/assets/images/cscs/', false, /^\.\/.*$/, 'lazy')
  
export default () => ({
  data: {
    CONTRACT_TYPES: [],
    EMPLOYMENT_TYPE: [],
    ENGLISH_LEVEL: [],
    EXPERIENCE_TYPE: [],
    EXPERIENCE_YEARS: [],
    LANGUAGE: [],
    QUALIFICATION: [],
    ROLE: [],
    SALARY_PERIOD: [],
    SKILL: [],
  },
  locations: [],
  static: {
    CONTRACT_STATUS: [
      { value: 'Past', id: 'PAST' },
      { value: 'Current', id: 'CURRENT' },
      { value: 'Future', id: 'FUTURE' },
      { value: 'Terminated', id: 'TERMINATED' },
    ],

    WORKER_CONTRACT_STATUS: [
      { value: 'In Progress', id: 'INCOMPLETE' },
      { value: 'Past', id: 'PAST' },
      { value: 'Current', id: 'CURRENT' },
      { value: 'Future', id: 'FUTURE' },
      { value: 'Unassigned', id: 'UNASSIGNED' },
    ],

    WORKER_STATUS: [
      { value: 'Past', id: 'PAST' },
      { value: 'Current', id: 'CURRENT' },
      { value: 'Future', id: 'FUTURE' },
    ],

    JOB_STATUS: [
      { value: 'Active', id: 'ACTIVE' },
      { value: 'Inactive', id: 'INACTIVE' },
      { value: 'All', id: 'ALL' },
    ],

    PAYMENT_CYCLE: [
      { value: 'Weekly', id: 'WEEKLY' },
      // { value: 'Biweekly', id: 'BIWEEKLY' },
      // { value: 'Monthly', id: 'MONTHLY' },
    ],

    CERTIFICATE_TYPE: [
      {
        id: 'ccdo',
        value: 'Certificate of Competence of Demolition Operatives',
      },
      {
        id: 'cisrs',
        value: 'Construction Industry Scaffolders Record Scheme',
      },
      {
        id: 'cpcs',
        value: 'Construction Plant Competence Scheme',
      },
      {
        id: 'cscs',
        value: 'Construction Skills Certificate Scheme',
      },
      {
        id: 'dcpc',
        value: 'Driver Certificate of Professional Competence',
      },
      {
        id: 'ecs',
        value: 'Electrotechnical Certification Scheme',
      },
      {
        id: 'ge',
        value: 'Gas Engineers',
      },
      {
        id: 'ipaf',
        value: 'The International Powered Access Federation',
      },
      {
        id: 'SSSTS',
        value: 'Site Supervision Safety Training Scheme',
      },
      {
        id: 'SMSTS',
        value: 'Site Manager Safety Training Scheme',
      },
      {
        id: 'jib',
        value: 'Joint Industry Board (Electricians)',
      },
      {
        id: 'mcpq-plant',
        value: 'Plant Operator Competency Scheme',
      },
      {
        id: 'sentinel',
        value: 'Rail Industry',
      },
      {
        id: 'siccs',
        value: 'Shopfitting and Interior Contracting Industry',
      },
      {
        id: 'snijib',
        value:
          'The Scottish and Northern Ireland Joint Industry Board (plumbing)',
      },
      {
        id: 'other',
        value: 'Other Certification',
      },
      {
        id: 'cv',
        value: 'Curriculum Vitae (CV)',
      },
      {
        id: 'first-aid-at-work',
        value: 'First Aid At Work',
      },
      {
        id: 'coshh',
        value: 'Control of Substances Hazardous to Health',
      },
      {
        id: 'glass-manual-handling',
        value: 'Glass Manual Handling',
      },
      {
        id: 'public-insurance',
        value: 'Public Liability Insurance',
      },
      {
        id: 'personal-insurance',
        value: 'Personal Insurance',
      },
    ],

    COMPANY_SIZE: [
      { value: '1-10', id: '1-10' },
      { value: '11-50', id: '11-50' },
      { value: '51-100', id: '51-100' },
      { value: '100+', id: '100+' },
    ],

    COMPANY_TURNOVER: [
      { value: '0-499,999', id: '0-499,999' },
      { value: '500,000-1,000,00', id: '500,000-1,000,00' },
      { value: '1,000,001-3,000,000', id: ' 1,000,001-3,000,000' },
      { value: '3,000,001-5,000,000', id: '3,000,001-5,000,000' },
      { value: '5,000,001-7,000,000', id: '5,000,001-7,000,000' },
      { value: '7,000,001-10,000,000', id: '7,000,001-10,000,000' },
      { value: '10,000,001-15,000,000', id: '10,000,001-15,000,000' },
      { value: '15,000,000+', id: '15,000,000+' },
    ],

    CSCS_SCHEMES: [
      { value: 'ACE', id: 'Z2T' },
      { value: 'ADIA', id: '1GT' },
      { value: 'ADSA', id: 'LO7' },
      { value: 'ALLMI', id: 'U19' },
      { value: 'CSR', id: '3W0' },
      { value: 'CSWIP', id: 'BR7' },
      { value: 'ECS (SJIB)', id: 'CFW' },
      { value: 'EUSR', id: 'HEZ' },
      { value: 'ICATS', id: 'WP8' },
      { value: 'IEXPE', id: 'OTK' },
      { value: 'LEEA', id: 'IQ7' },
      { value: 'MPQC', id: '3D2' },
      { value: 'SAFed', id: 'XL6' },
      { value: 'SEIRS', id: '3GK' },
      { value: 'SNIJIB', id: '3BX' },
      { value: 'ACAD', id: 'WKN' },
      { value: 'ARMI', id: 'JHW' },
      { value: 'CCDO', id: 'PHM' },
      { value: 'CSCS', id: 'C4T' },
      { value: 'Engineering Services Skillcard', id: '9ZA' },
      { value: 'FASET', id: 'P4B' },
      { value: 'FISS', id: 'X5U' },
      { value: 'GEA', id: '62O' },
      { value: 'JIB PMES', id: 'OUQ' },
      { value: 'LISS', id: 'YXO' },
      { value: 'SICCS', id: 'BS5' },
      { value: 'TICA', id: 'MRD' },
      { value: 'TTM', id: '4UC' },
      { value: 'BFBi', id: 'SDQ' },
      { value: 'DSA', id: '6DU' },
      { value: 'ECS (JIB)', id: 'ECS' },
      { value: 'NPORS', id: 'P5Y' },
      { value: 'Q-card OR GQA', id: 'SR8' },
      { value: 'SSPC Trainthepainter', id: '0KC' },
      { value: 'CISRS', id: '7CM' },
      { value: 'CPCS', id: 'W60' },
      { value: 'IPAF', id: 'ROT' },
      { value: 'PASMA', id: 'R7S' },
      { value: 'Lloyds British', id: 'GPR' },
      { value: 'HSB', id: 'W3J' },
      { value: 'British Engineering Services', id: 'NWQ' },
      { value: 'Allianz UK', id: 'VKB' },
      { value: 'Academy SSPC', id: 'ACY' }
    ].sort( (obj1, obj2) => obj1.value < obj2.value ? -1 : obj1.value > obj2.value ? 1 : 0 ),
    
    CSCS_SCHEMES_PLACEHOLDER_CARD_IMAGE: require('~/assets/images/cscs/placeholder.png'),
    CSCS_SCHEMES_UNKNOWN_CARD_IMAGE: require('~/assets/images/cscs/unknown.png'),
    CSCS_SCHEMES2: cscs_cards
                    .map( (card) => {
                      let srcSS = null
                      if (card.src?.length) {
                        const extStart = card.src.lastIndexOf(".")
                        if (extStart !== -1) {
                          const ext = card.src.substring(extStart)
                          const resourceName = card.id + "-" + card.code + ext
                          srcSS = require(`~/assets/images/cscs/${resourceName}`)
                        }
                      }
                      card.srcSS = srcSS
                      return card
                    })
                    .sort( 
                      (obj1, obj2) => obj1.long < obj2.long ? -1 : obj1.long > obj2.long ? 1 : 0 
                    ),

    COUNTRIES: [
      {
        value: 'United Kingdom',
        id: 'United Kingdom',
      },
      {
        value: 'Afghanistan',
        id: 'Afghanistan',
      },
      {
        value: 'Albania',
        id: 'Albania',
      },
      {
        value: 'Algeria',
        id: 'Algeria',
      },
      {
        value: 'Andorra',
        id: 'Andorra',
      },
      {
        value: 'Angola',
        id: 'Angola',
      },
      {
        value: 'Antigua & Deps',
        id: 'Antigua & Deps',
      },
      {
        value: 'Argentina',
        id: 'Argentina',
      },
      {
        value: 'Armenia',
        id: 'Armenia',
      },
      {
        value: 'Australia',
        id: 'Australia',
      },
      {
        value: 'Austria',
        id: 'Austria',
      },
      {
        value: 'Azerbaijan',
        id: 'Azerbaijan',
      },
      {
        value: 'Bahamas',
        id: 'Bahamas',
      },
      {
        value: 'Bahrain',
        id: 'Bahrain',
      },
      {
        value: 'Bangladesh',
        id: 'Bangladesh',
      },
      {
        value: 'Barbados',
        id: 'Barbados',
      },
      {
        value: 'Belarus',
        id: 'Belarus',
      },
      {
        value: 'Belgium',
        id: 'Belgium',
      },
      {
        value: 'Belize',
        id: 'Belize',
      },
      {
        value: 'Benin',
        id: 'Benin',
      },
      {
        value: 'Bhutan',
        id: 'Bhutan',
      },
      {
        value: 'Bolivia',
        id: 'Bolivia',
      },
      {
        value: 'Bosnia Herzegovina',
        id: 'Bosnia Herzegovina',
      },
      {
        value: 'Botswana',
        id: 'Botswana',
      },
      {
        value: 'Brazil',
        id: 'Brazil',
      },
      {
        value: 'Brunei',
        id: 'Brunei',
      },
      {
        value: 'Bulgaria',
        id: 'Bulgaria',
      },
      {
        value: 'Burkina',
        id: 'Burkina',
      },
      {
        value: 'Burundi',
        id: 'Burundi',
      },
      {
        value: 'Cambodia',
        id: 'Cambodia',
      },
      {
        value: 'Cameroon',
        id: 'Cameroon',
      },
      {
        value: 'Canada',
        id: 'Canada',
      },
      {
        value: 'Cape Verde',
        id: 'Cape Verde',
      },
      {
        value: 'Central African Rep',
        id: 'Central African Rep',
      },
      {
        value: 'Chad',
        id: 'Chad',
      },
      {
        value: 'Chile',
        id: 'Chile',
      },
      {
        value: 'China',
        id: 'China',
      },
      {
        value: 'Colombia',
        id: 'Colombia',
      },
      {
        value: 'Comoros',
        id: 'Comoros',
      },
      {
        value: 'Congo',
        id: 'Congo',
      },
      {
        value: 'Congo {Democratic Rep}',
        id: 'Congo {Democratic Rep}',
      },
      {
        value: 'Costa Rica',
        id: 'Costa Rica',
      },
      {
        value: 'Croatia',
        id: 'Croatia',
      },
      {
        value: 'Cuba',
        id: 'Cuba',
      },
      {
        value: 'Cyprus',
        id: 'Cyprus',
      },
      {
        value: 'Czech Republic',
        id: 'Czech Republic',
      },
      {
        value: 'Denmark',
        id: 'Denmark',
      },
      {
        value: 'Djibouti',
        id: 'Djibouti',
      },
      {
        value: 'Dominica',
        id: 'Dominica',
      },
      {
        value: 'Dominican Republic',
        id: 'Dominican Republic',
      },
      {
        value: 'East Timor',
        id: 'East Timor',
      },
      {
        value: 'Ecuador',
        id: 'Ecuador',
      },
      {
        value: 'Egypt',
        id: 'Egypt',
      },
      {
        value: 'El Salvador',
        id: 'El Salvador',
      },
      {
        value: 'Equatorial Guinea',
        id: 'Equatorial Guinea',
      },
      {
        value: 'Eritrea',
        id: 'Eritrea',
      },
      {
        value: 'Estonia',
        id: 'Estonia',
      },
      {
        value: 'Ethiopia',
        id: 'Ethiopia',
      },
      {
        value: 'Fiji',
        id: 'Fiji',
      },
      {
        value: 'Finland',
        id: 'Finland',
      },
      {
        value: 'France',
        id: 'France',
      },
      {
        value: 'Gabon',
        id: 'Gabon',
      },
      {
        value: 'Gambia',
        id: 'Gambia',
      },
      {
        value: 'Georgia',
        id: 'Georgia',
      },
      {
        value: 'Germany',
        id: 'Germany',
      },
      {
        value: 'Ghana',
        id: 'Ghana',
      },
      {
        value: 'Greece',
        id: 'Greece',
      },
      {
        value: 'Grenada',
        id: 'Grenada',
      },
      {
        value: 'Guatemala',
        id: 'Guatemala',
      },
      {
        value: 'Guinea',
        id: 'Guinea',
      },
      {
        value: 'Guinea-Bissau',
        id: 'Guinea-Bissau',
      },
      {
        value: 'Guyana',
        id: 'Guyana',
      },
      {
        value: 'Haiti',
        id: 'Haiti',
      },
      {
        value: 'Honduras',
        id: 'Honduras',
      },
      {
        value: 'Hungary',
        id: 'Hungary',
      },
      {
        value: 'Iceland',
        id: 'Iceland',
      },
      {
        value: 'India',
        id: 'India',
      },
      {
        value: 'Indonesia',
        id: 'Indonesia',
      },
      {
        value: 'Iran',
        id: 'Iran',
      },
      {
        value: 'Iraq',
        id: 'Iraq',
      },
      {
        value: 'Ireland {Republic}',
        id: 'Ireland {Republic}',
      },
      {
        value: 'Israel',
        id: 'Israel',
      },
      {
        value: 'Italy',
        id: 'Italy',
      },
      {
        value: 'Ivory Coast',
        id: 'Ivory Coast',
      },
      {
        value: 'Jamaica',
        id: 'Jamaica',
      },
      {
        value: 'Japan',
        id: 'Japan',
      },
      {
        value: 'Jordan',
        id: 'Jordan',
      },
      {
        value: 'Kazakhstan',
        id: 'Kazakhstan',
      },
      {
        value: 'Kenya',
        id: 'Kenya',
      },
      {
        value: 'Kiribati',
        id: 'Kiribati',
      },
      {
        value: 'Korea North',
        id: 'Korea North',
      },
      {
        value: 'Korea South',
        id: 'Korea South',
      },
      {
        value: 'Kosovo',
        id: 'Kosovo',
      },
      {
        value: 'Kuwait',
        id: 'Kuwait',
      },
      {
        value: 'Kyrgyzstan',
        id: 'Kyrgyzstan',
      },
      {
        value: 'Laos',
        id: 'Laos',
      },
      {
        value: 'Latvia',
        id: 'Latvia',
      },
      {
        value: 'Lebanon',
        id: 'Lebanon',
      },
      {
        value: 'Lesotho',
        id: 'Lesotho',
      },
      {
        value: 'Liberia',
        id: 'Liberia',
      },
      {
        value: 'Libya',
        id: 'Libya',
      },
      {
        value: 'Liechtenstein',
        id: 'Liechtenstein',
      },
      {
        value: 'Lithuania',
        id: 'Lithuania',
      },
      {
        value: 'Luxembourg',
        id: 'Luxembourg',
      },
      {
        value: 'Macedonia',
        id: 'Macedonia',
      },
      {
        value: 'Madagascar',
        id: 'Madagascar',
      },
      {
        value: 'Malawi',
        id: 'Malawi',
      },
      {
        value: 'Malaysia',
        id: 'Malaysia',
      },
      {
        value: 'Maldives',
        id: 'Maldives',
      },
      {
        value: 'Mali',
        id: 'Mali',
      },
      {
        value: 'Malta',
        id: 'Malta',
      },
      {
        value: 'Marshall Islands',
        id: 'Marshall Islands',
      },
      {
        value: 'Mauritania',
        id: 'Mauritania',
      },
      {
        value: 'Mauritius',
        id: 'Mauritius',
      },
      {
        value: 'Mexico',
        id: 'Mexico',
      },
      {
        value: 'Micronesia',
        id: 'Micronesia',
      },
      {
        value: 'Moldova',
        id: 'Moldova',
      },
      {
        value: 'Monaco',
        id: 'Monaco',
      },
      {
        value: 'Mongolia',
        id: 'Mongolia',
      },
      {
        value: 'Montenegro',
        id: 'Montenegro',
      },
      {
        value: 'Morocco',
        id: 'Morocco',
      },
      {
        value: 'Mozambique',
        id: 'Mozambique',
      },
      {
        value: 'Myanmar, {Burma}',
        id: 'Myanmar, {Burma}',
      },
      {
        value: 'Namibia',
        id: 'Namibia',
      },
      {
        value: 'Nauru',
        id: 'Nauru',
      },
      {
        value: 'Nepal',
        id: 'Nepal',
      },
      {
        value: 'Netherlands',
        id: 'Netherlands',
      },
      {
        value: 'New Zealand',
        id: 'New Zealand',
      },
      {
        value: 'Nicaragua',
        id: 'Nicaragua',
      },
      {
        value: 'Niger',
        id: 'Niger',
      },
      {
        value: 'Nigeria',
        id: 'Nigeria',
      },
      {
        value: 'Norway',
        id: 'Norway',
      },
      {
        value: 'Oman',
        id: 'Oman',
      },
      {
        value: 'Pakistan',
        id: 'Pakistan',
      },
      {
        value: 'Palau',
        id: 'Palau',
      },
      {
        value: 'Panama',
        id: 'Panama',
      },
      {
        value: 'Papua New Guinea',
        id: 'Papua New Guinea',
      },
      {
        value: 'Paraguay',
        id: 'Paraguay',
      },
      {
        value: 'Peru',
        id: 'Peru',
      },
      {
        value: 'Philippines',
        id: 'Philippines',
      },
      {
        value: 'Poland',
        id: 'Poland',
      },
      {
        value: 'Portugal',
        id: 'Portugal',
      },
      {
        value: 'Qatar',
        id: 'Qatar',
      },
      {
        value: 'Romania',
        id: 'Romania',
      },
      {
        value: 'Russian Federation',
        id: 'Russian Federation',
      },
      {
        value: 'Rwanda',
        id: 'Rwanda',
      },
      {
        value: 'St Kitts & Nevis',
        id: 'St Kitts & Nevis',
      },
      {
        value: 'St Lucia',
        id: 'St Lucia',
      },
      {
        value: 'Saint Vincent & the Grenadines',
        id: 'Saint Vincent & the Grenadines',
      },
      {
        value: 'Samoa',
        id: 'Samoa',
      },
      {
        value: 'San Marino',
        id: 'San Marino',
      },
      {
        value: 'Sao Tome & Principe',
        id: 'Sao Tome & Principe',
      },
      {
        value: 'Saudi Arabia',
        id: 'Saudi Arabia',
      },
      {
        value: 'Senegal',
        id: 'Senegal',
      },
      {
        value: 'Serbia',
        id: 'Serbia',
      },
      {
        value: 'Seychelles',
        id: 'Seychelles',
      },
      {
        value: 'Sierra Leone',
        id: 'Sierra Leone',
      },
      {
        value: 'Singapore',
        id: 'Singapore',
      },
      {
        value: 'Slovakia',
        id: 'Slovakia',
      },
      {
        value: 'Slovenia',
        id: 'Slovenia',
      },
      {
        value: 'Solomon Islands',
        id: 'Solomon Islands',
      },
      {
        value: 'Somalia',
        id: 'Somalia',
      },
      {
        value: 'South Africa',
        id: 'South Africa',
      },
      {
        value: 'South Sudan',
        id: 'South Sudan',
      },
      {
        value: 'Spain',
        id: 'Spain',
      },
      {
        value: 'Sri Lanka',
        id: 'Sri Lanka',
      },
      {
        value: 'Sudan',
        id: 'Sudan',
      },
      {
        value: 'Suriname',
        id: 'Suriname',
      },
      {
        value: 'Swaziland',
        id: 'Swaziland',
      },
      {
        value: 'Sweden',
        id: 'Sweden',
      },
      {
        value: 'Switzerland',
        id: 'Switzerland',
      },
      {
        value: 'Syria',
        id: 'Syria',
      },
      {
        value: 'Taiwan',
        id: 'Taiwan',
      },
      {
        value: 'Tajikistan',
        id: 'Tajikistan',
      },
      {
        value: 'Tanzania',
        id: 'Tanzania',
      },
      {
        value: 'Thailand',
        id: 'Thailand',
      },
      {
        value: 'Togo',
        id: 'Togo',
      },
      {
        value: 'Tonga',
        id: 'Tonga',
      },
      {
        value: 'Trinidad & Tobago',
        id: 'Trinidad & Tobago',
      },
      {
        value: 'Tunisia',
        id: 'Tunisia',
      },
      {
        value: 'Turkey',
        id: 'Turkey',
      },
      {
        value: 'Turkmenistan',
        id: 'Turkmenistan',
      },
      {
        value: 'Tuvalu',
        id: 'Tuvalu',
      },
      {
        value: 'Uganda',
        id: 'Uganda',
      },
      {
        value: 'Ukraine',
        id: 'Ukraine',
      },
      {
        value: 'United Arab Emirates',
        id: 'United Arab Emirates',
      },
      {
        value: 'United States',
        id: 'United States',
      },
      {
        value: 'Uruguay',
        id: 'Uruguay',
      },
      {
        value: 'Uzbekistan',
        id: 'Uzbekistan',
      },
      {
        value: 'Vanuatu',
        id: 'Vanuatu',
      },
      {
        value: 'Vatican City',
        id: 'Vatican City',
      },
      {
        value: 'Venezuela',
        id: 'Venezuela',
      },
      {
        value: 'Vietnam',
        id: 'Vietnam',
      },
      {
        value: 'Yemen',
        id: 'Yemen',
      },
      {
        value: 'Zambia',
        id: 'Zambia',
      },
      {
        value: 'Zimbabwe',
        id: 'Zimbabwe',
      },
    ],

    NATIONALITIES: [
      {
        value: 'British',
        id: 'British',
      },
      {
        value: 'Polish',
        id: 'Polish',
      },
      {
        value: 'Afghan',
        id: 'Afghan',
      },
      {
        value: 'Albanian',
        id: 'Albanian',
      },
      {
        value: 'Algerian',
        id: 'Algerian',
      },
      {
        value: 'American',
        id: 'American',
      },
      {
        value: 'Andorran',
        id: 'Andorran',
      },
      {
        value: 'Angolan',
        id: 'Angolan',
      },
      {
        value: 'Antiguans',
        id: 'Antiguans',
      },
      {
        value: 'Argentinean',
        id: 'Argentinean',
      },
      {
        value: 'Armenian',
        id: 'Armenian',
      },
      {
        value: 'Australian',
        id: 'Australian',
      },
      {
        value: 'Austrian',
        id: 'Austrian',
      },
      {
        value: 'Azerbaijani',
        id: 'Azerbaijani',
      },
      {
        value: 'Bahamian',
        id: 'Bahamian',
      },
      {
        value: 'Bahraini',
        id: 'Bahraini',
      },
      {
        value: 'Bangladeshi',
        id: 'Bangladeshi',
      },
      {
        value: 'Barbadian',
        id: 'Barbadian',
      },
      {
        value: 'Barbudans',
        id: 'Barbudans',
      },
      {
        value: 'Batswana',
        id: 'Batswana',
      },
      {
        value: 'Belarusian',
        id: 'Belarusian',
      },
      {
        value: 'Belgian',
        id: 'Belgian',
      },
      {
        value: 'Belizean',
        id: 'Belizean',
      },
      {
        value: 'Beninese',
        id: 'Beninese',
      },
      {
        value: 'Bhutanese',
        id: 'Bhutanese',
      },
      {
        value: 'Bolivian',
        id: 'Bolivian',
      },
      {
        value: 'Bosnian',
        id: 'Bosnian',
      },
      {
        value: 'Brazilian',
        id: 'Brazilian',
      },

      {
        value: 'Bruneian',
        id: 'Bruneian',
      },
      {
        value: 'Bulgarian',
        id: 'Bulgarian',
      },
      {
        value: 'Burkinabe',
        id: 'Burkinabe',
      },
      {
        value: 'Burmese',
        id: 'Burmese',
      },
      {
        value: 'Burundian',
        id: 'Burundian',
      },
      {
        value: 'Cambodian',
        id: 'Cambodian',
      },
      {
        value: 'Cameroonian',
        id: 'Cameroonian',
      },
      {
        value: 'Canadian',
        id: 'Canadian',
      },
      {
        value: 'Cape Verdean',
        id: 'Cape Verdean',
      },
      {
        value: 'Central African',
        id: 'Central African',
      },
      {
        value: 'Chadian',
        id: 'Chadian',
      },
      {
        value: 'Chilean',
        id: 'Chilean',
      },
      {
        value: 'Chinese',
        id: 'Chinese',
      },
      {
        value: 'Colombian',
        id: 'Colombian',
      },
      {
        value: 'Comoran',
        id: 'Comoran',
      },
      {
        value: 'Congolese',
        id: 'Congolese',
      },
      {
        value: 'Congolese',
        id: 'Congolese',
      },
      {
        value: 'Costa Rican',
        id: 'Costa Rican',
      },
      {
        value: 'Croatian',
        id: 'Croatian',
      },
      {
        value: 'Cuban',
        id: 'Cuban',
      },
      {
        value: 'Cypriot',
        id: 'Cypriot',
      },
      {
        value: 'Czech',
        id: 'Czech',
      },
      {
        value: 'Danish',
        id: 'Danish',
      },
      {
        value: 'Djibouti',
        id: 'Djibouti',
      },
      {
        value: 'Dominican',
        id: 'Dominican',
      },
      {
        value: 'Dominican',
        id: 'Dominican',
      },
      {
        value: 'Dutch',
        id: 'Dutch',
      },
      {
        value: 'Dutchman',
        id: 'Dutchman',
      },
      {
        value: 'Dutchwoman',
        id: 'Dutchwoman',
      },
      {
        value: 'East Timorese',
        id: 'East Timorese',
      },
      {
        value: 'Ecuadorean',
        id: 'Ecuadorean',
      },
      {
        value: 'Egyptian',
        id: 'Egyptian',
      },
      {
        value: 'Emirian',
        id: 'Emirian',
      },
      {
        value: 'Equatorial Guinean',
        id: 'Equatorial Guinean',
      },
      {
        value: 'Eritrean',
        id: 'Eritrean',
      },
      {
        value: 'Estonian',
        id: 'Estonian',
      },
      {
        value: 'Ethiopian',
        id: 'Ethiopian',
      },
      {
        value: 'Fijian',
        id: 'Fijian',
      },
      {
        value: 'Filipino',
        id: 'Filipino',
      },
      {
        value: 'Finnish',
        id: 'Finnish',
      },
      {
        value: 'French',
        id: 'French',
      },
      {
        value: 'Gabonese',
        id: 'Gabonese',
      },
      {
        value: 'Gambian',
        id: 'Gambian',
      },
      {
        value: 'Georgian',
        id: 'Georgian',
      },
      {
        value: 'German',
        id: 'German',
      },
      {
        value: 'Ghanaian',
        id: 'Ghanaian',
      },
      {
        value: 'Greek',
        id: 'Greek',
      },
      {
        value: 'Grenadian',
        id: 'Grenadian',
      },
      {
        value: 'Guatemalan',
        id: 'Guatemalan',
      },
      {
        value: 'Guinea-Bissauan',
        id: 'Guinea-Bissauan',
      },
      {
        value: 'Guinean',
        id: 'Guinean',
      },
      {
        value: 'Guyanese',
        id: 'Guyanese',
      },
      {
        value: 'Haitian',
        id: 'Haitian',
      },
      {
        value: 'Herzegovinian',
        id: 'Herzegovinian',
      },
      {
        value: 'Honduran',
        id: 'Honduran',
      },
      {
        value: 'Hungarian',
        id: 'Hungarian',
      },
      {
        value: 'I-Kiribati',
        id: 'I-Kiribati',
      },
      {
        value: 'Icelander',
        id: 'Icelander',
      },
      {
        value: 'Indian',
        id: 'Indian',
      },
      {
        value: 'Indonesian',
        id: 'Indonesian',
      },
      {
        value: 'Iranian',
        id: 'Iranian',
      },
      {
        value: 'Iraqi',
        id: 'Iraqi',
      },
      {
        value: 'Irish',
        id: 'Irish',
      },
      {
        value: 'Irish',
        id: 'Irish',
      },
      {
        value: 'Israeli',
        id: 'Israeli',
      },
      {
        value: 'Italian',
        id: 'Italian',
      },
      {
        value: 'Ivorian',
        id: 'Ivorian',
      },
      {
        value: 'Jamaican',
        id: 'Jamaican',
      },
      {
        value: 'Japanese',
        id: 'Japanese',
      },
      {
        value: 'Jordanian',
        id: 'Jordanian',
      },
      {
        value: 'Kazakhstani',
        id: 'Kazakhstani',
      },
      {
        value: 'Kenyan',
        id: 'Kenyan',
      },
      {
        value: 'Kittian and Nevisian',
        id: 'Kittian and Nevisian',
      },
      {
        value: 'Kuwaiti',
        id: 'Kuwaiti',
      },
      {
        value: 'Kyrgyz',
        id: 'Kyrgyz',
      },
      {
        value: 'Laotian',
        id: 'Laotian',
      },
      {
        value: 'Latvian',
        id: 'Latvian',
      },
      {
        value: 'Lebanese',
        id: 'Lebanese',
      },
      {
        value: 'Liberian',
        id: 'Liberian',
      },
      {
        value: 'Libyan',
        id: 'Libyan',
      },
      {
        value: 'Liechtensteiner',
        id: 'Liechtensteiner',
      },
      {
        value: 'Lithuanian',
        id: 'Lithuanian',
      },
      {
        value: 'Luxembourger',
        id: 'Luxembourger',
      },
      {
        value: 'Macedonian',
        id: 'Macedonian',
      },
      {
        value: 'Malagasy',
        id: 'Malagasy',
      },
      {
        value: 'Malawian',
        id: 'Malawian',
      },
      {
        value: 'Malaysian',
        id: 'Malaysian',
      },
      {
        value: 'Maldivan',
        id: 'Maldivan',
      },
      {
        value: 'Malian',
        id: 'Malian',
      },
      {
        value: 'Maltese',
        id: 'Maltese',
      },
      {
        value: 'Marshallese',
        id: 'Marshallese',
      },
      {
        value: 'Mauritanian',
        id: 'Mauritanian',
      },
      {
        value: 'Mauritian',
        id: 'Mauritian',
      },
      {
        value: 'Mexican',
        id: 'Mexican',
      },
      {
        value: 'Micronesian',
        id: 'Micronesian',
      },
      {
        value: 'Moldovan',
        id: 'Moldovan',
      },
      {
        value: 'Monacan',
        id: 'Monacan',
      },
      {
        value: 'Mongolian',
        id: 'Mongolian',
      },
      {
        value: 'Moroccan',
        id: 'Moroccan',
      },
      {
        value: 'Mosotho',
        id: 'Mosotho',
      },
      {
        value: 'Motswana',
        id: 'Motswana',
      },
      {
        value: 'Mozambican',
        id: 'Mozambican',
      },
      {
        value: 'Namibian',
        id: 'Namibian',
      },
      {
        value: 'Nauruan',
        id: 'Nauruan',
      },
      {
        value: 'Nepalese',
        id: 'Nepalese',
      },
      {
        value: 'Netherlander',
        id: 'Netherlander',
      },
      {
        value: 'New Zealander',
        id: 'New Zealander',
      },
      {
        value: 'Ni-Vanuatu',
        id: 'Ni-Vanuatu',
      },
      {
        value: 'Nicaraguan',
        id: 'Nicaraguan',
      },
      {
        value: 'Nigerian',
        id: 'Nigerian',
      },
      {
        value: 'Nigerien',
        id: 'Nigerien',
      },
      {
        value: 'North Korean',
        id: 'North Korean',
      },
      {
        value: 'Northern Irish',
        id: 'Northern Irish',
      },
      {
        value: 'Norwegian',
        id: 'Norwegian',
      },
      {
        value: 'Omani',
        id: 'Omani',
      },
      {
        value: 'Pakistani',
        id: 'Pakistani',
      },
      {
        value: 'Palauan',
        id: 'Palauan',
      },
      {
        value: 'Panamanian',
        id: 'Panamanian',
      },
      {
        value: 'Papua New Guinean',
        id: 'Papua New Guinean',
      },
      {
        value: 'Paraguayan',
        id: 'Paraguayan',
      },
      {
        value: 'Peruvian',
        id: 'Peruvian',
      },

      {
        value: 'Portuguese',
        id: 'Portuguese',
      },
      {
        value: 'Qatari',
        id: 'Qatari',
      },
      {
        value: 'Romanian',
        id: 'Romanian',
      },
      {
        value: 'Russian',
        id: 'Russian',
      },
      {
        value: 'Rwandan',
        id: 'Rwandan',
      },
      {
        value: 'Saint Lucian',
        id: 'Saint Lucian',
      },
      {
        value: 'Salvadoran',
        id: 'Salvadoran',
      },
      {
        value: 'Samoan',
        id: 'Samoan',
      },
      {
        value: 'San Marinese',
        id: 'San Marinese',
      },
      {
        value: 'Sao Tomean',
        id: 'Sao Tomean',
      },
      {
        value: 'Saudi',
        id: 'Saudi',
      },
      {
        value: 'Scottish',
        id: 'Scottish',
      },
      {
        value: 'Senegalese',
        id: 'Senegalese',
      },
      {
        value: 'Serbian',
        id: 'Serbian',
      },
      {
        value: 'Seychellois',
        id: 'Seychellois',
      },
      {
        value: 'Sierra Leonean',
        id: 'Sierra Leonean',
      },
      {
        value: 'Singaporean',
        id: 'Singaporean',
      },
      {
        value: 'Slovakian',
        id: 'Slovakian',
      },
      {
        value: 'Slovenian',
        id: 'Slovenian',
      },
      {
        value: 'Solomon Islander',
        id: 'Solomon Islander',
      },
      {
        value: 'Somali',
        id: 'Somali',
      },
      {
        value: 'South African',
        id: 'South African',
      },
      {
        value: 'South Korean',
        id: 'South Korean',
      },
      {
        value: 'Spanish',
        id: 'Spanish',
      },
      {
        value: 'Sri Lankan',
        id: 'Sri Lankan',
      },
      {
        value: 'Sudanese',
        id: 'Sudanese',
      },
      {
        value: 'Surinamer',
        id: 'Surinamer',
      },
      {
        value: 'Swazi',
        id: 'Swazi',
      },
      {
        value: 'Swedish',
        id: 'Swedish',
      },
      {
        value: 'Swiss',
        id: 'Swiss',
      },
      {
        value: 'Syrian',
        id: 'Syrian',
      },
      {
        value: 'Taiwanese',
        id: 'Taiwanese',
      },
      {
        value: 'Tajik',
        id: 'Tajik',
      },
      {
        value: 'Tanzanian',
        id: 'Tanzanian',
      },
      {
        value: 'Thai',
        id: 'Thai',
      },
      {
        value: 'Togolese',
        id: 'Togolese',
      },
      {
        value: 'Tongan',
        id: 'Tongan',
      },
      {
        value: 'Trinidadian or Tobagonian',
        id: 'Trinidadian or Tobagonian',
      },
      {
        value: 'Tunisian',
        id: 'Tunisian',
      },
      {
        value: 'Turkish',
        id: 'Turkish',
      },
      {
        value: 'Tuvaluan',
        id: 'Tuvaluan',
      },
      {
        value: 'Ugandan',
        id: 'Ugandan',
      },
      {
        value: 'Ukrainian',
        id: 'Ukrainian',
      },
      {
        value: 'Uruguayan',
        id: 'Uruguayan',
      },
      {
        value: 'Uzbekistani',
        id: 'Uzbekistani',
      },
      {
        value: 'Venezuelan',
        id: 'Venezuelan',
      },
      {
        value: 'Vietnamese',
        id: 'Vietnamese',
      },
      {
        value: 'Welsh',
        id: 'Welsh',
      },
      {
        value: 'Welsh',
        id: 'Welsh',
      },
      {
        value: 'Yemenite',
        id: 'Yemenite',
      },
      {
        value: 'Zambian',
        id: 'Zambian',
      },
      {
        value: 'Zimbabwean',
        id: 'Zimbabwean',
      },
    ],
  },
  hasData: false,
});
