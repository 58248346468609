import jwt                    from "jsonwebtoken";
import {ReactNativeMessages}  from "@/mixins/reactNativeChannel";
import ReactNativeChannel     from '@/mixins/reactNativeChannel';
import {isDebug, isInWebView} from "@/helpers/environment";
import {phoneNumberAddPrefix} from "@/helpers/helpers";
import {disableLoader, enableLoader} from "@/helpers/loader";
import {WRS_COMPLETED, WRS_FINISHED_STEP_ID} from "@/components/WorkerRegistration/constants.ts";

const processApiForm = async ({commit}, request) => {
  enableLoader()
  commit('setError', '');
  try {
    return await request();
  } catch (err) {
    commit('setError', err);
    throw err;
  } finally {
    disableLoader()
  }
}
export default {
  async verifyPhone({commit}, {phone_number}) {
    return await processApiForm({commit}, async () => {
      let withPrefix = phoneNumberAddPrefix(phone_number)
      const ret = await this.$axios.post('/api/worker-registration/phone-verification/verify-phone', {
        phone_number: withPrefix
      })
      commit('phoneNumber', withPrefix);
      return ret;
    })
  },
  async verifyCode({commit, state}, {code}) {
    return await processApiForm({commit}, async () => {
      return await this.$axios.post(
        '/api/worker-registration/phone-verification/verify-code', {
          phone_number: state.phoneNumber,
          code
        }
      ).then((result) => {
        commit('verificationCode', code)
        return result;
      })
    })
  },
  async forgotPassword({commit, state}) {
    return await processApiForm({commit}, async () => {
      return await this.$axios.post(
        '/api/worker-registration/phone-verification/resend-code',
        {phone_number: state.phoneNumber}
      )
    })
  },
  async iDontHaveCode({commit, state}) {
    return await processApiForm({commit}, async () => {
      return await this.$axios.post(
        '/api/worker-registration/phone-verification/i-dont-have-code',
        {phone_number: state.phoneNumber}
      )
    })
  },
  async setPassword({commit, state}, {password}) {
    return await processApiForm({commit}, async () => {
      return await this.$axios.post('/api/worker-registration/phone-verification/password', {
        phone_number: state.phoneNumber,
        code: state.verificationCode,
        password,
      }).then(({data: auth}) => {
        const decoded = jwt.decode(auth.tokenInfo.accessToken);
        commit('auth/mapUser', decoded.usr, {root: true});
        commit('auth/setToken', auth.tokenInfo.accessToken, {root: true});
        return auth;
      })
    })
  },
  async login({commit}, data) {
    enableLoader()
    try {
      return await this.$axios.post('/api/worker-registration/phone-verification/login', data)
        .then(({data: auth}) => {
          const token = auth.tokenInfo.accessToken
          const decoded = jwt.decode(token);
          commit('auth/mapUser', decoded.usr, {root: true});
          commit('auth/setToken', token, {root: true});
          return token
        })
    } catch (err) {
      // fix errors formatting from backend..
      if (err.response.data.status === false && err.response.data.message && !err.response.data.errors) {
        err.response.data.errors = {
          password: [err.response.data.message]
        }
      }
      throw err;
    } finally {
      disableLoader()
    }
  },
  async fetchStatusAndCheckComplete({commit, dispatch, rootGetters}, nextStepId) {
    
    let fetchStatusResult = null;
    try {
      if (nextStepId !== WRS_COMPLETED && nextStepId != WRS_FINISHED_STEP_ID) {
        //Unless forced to end step, check with server...
          fetchStatusResult = await dispatch('fetchStatus')
      }
    } catch (e) {
      console.log(e)
    }

    if (fetchStatusResult) {
      //what did server say?
      nextStepId = fetchStatusResult['next-step']
      if (nextStepId === WRS_COMPLETED) {
        //server says we are done, show welcome page 
        nextStepId = WRS_FINISHED_STEP_ID
      }
    }

    if (nextStepId === WRS_COMPLETED) {
      //Will be here if caller forced to completed stage

      //Check token is up to date!
      const user = rootGetters['auth/getUser']
      if (user?.status !== 'COMPLETED') {
        //Token MUST have completed status so refresh it
        await dispatch('auth/refreshToken', {notifyParent: true}, {root: true})
      }

      //Let App Know User is all done with registration
      setTimeout(() => {
        ReactNativeChannel.methods.webViewPostMessage(ReactNativeMessages.REGISTRATION_PROCESS_COMPLETE);
      })
    } else {
      //Will be here if server returned that the profile is completed
      //Or the server failed to return a next step
      commit('setCurrentStep', nextStepId)
    }
  },
  async fetchStatus({commit}, data) {
    try {
      return await this.$axios.get('/api/worker-registration/status', data)
        .then(({data}) => {
          const stepsData = data['steps-data'];
          commit('applicantId', stepsData.onfido.applicant_id);
          commit('checkId', stepsData.onfido.check_id);
          commit('checkStatus', stepsData.onfido.check_status);
          commit('setStepsData', stepsData)
          return data;
        });
    } catch (err) {
      // TODO: handle error
      throw err;
    }
  },
  async fetchOnfidoCheckStatus({commit, state}) {
    try {
      return await this.$axios.post('/api/worker-registration/onfido/check/status/fetch', {
        applicant_id: state.applicantId
      })
        .then(({data}) => {
          commit('checkStatus', data.check.status);
          return data;
        });
    } catch (err) {
      // TODO: handle error
      return err.response.data
      // throw err;
    }
  },
  async createOnfidoApplicant({commit, state}) {
    enableLoader()
    try {
      return await this.$axios.post('/api/worker-registration/onfido/applicant', {
        device: navigator && navigator.userAgent ? navigator.userAgent : null
      })
        .then(({data}) => {
          commit('applicantId', data.applicant_id);
          return data;
        });
    } catch (err) {
      throw err;
    } finally {
      disableLoader()
    }
  },
  async createOnfidoCheck({commit, state}) {
    enableLoader()
    try {
      let request;
      if (isDebug() && !isInWebView()) {
        request = this.$axios.post(
          '/api/debug-helpers/simulate-onfido-check-created',
          {applicant_id: state.applicantId}
        )
      } else {
        request = this.$axios.post('/api/worker-registration/onfido/check', {
          applicant_id: state.applicantId
        })
      }

      let data
      try {
        data = await request
        commit('checkId', data.check_id);
      } catch (err) {
        data = err
        throw err;
      }

      return data;

    } catch (err) {
      // TODO: handle error
      throw err;
    }
    finally {
      disableLoader()
    }
  },
  async setSkillPhotos({commit, state}, {front_photo, back_photo, skill_card_number, skill_card_exp, skill_card_surname, skill_card_scheme_name}) {
    return await processApiForm({commit}, async () => {
      //TODO: currently for demo we load images as base64 and here we need to reformat them to blob. Probably better to change format to accept already in blob/binary from react native
      const frontPhotoAsBlob = await fetch(front_photo).then(res => res.blob());
      const backPhotoAsBlob = await fetch(back_photo).then(res => res.blob());

      const formData = new FormData();
      formData.append('front_photo', frontPhotoAsBlob);
      formData.append('back_photo', backPhotoAsBlob);
      formData.append('skill_card_number', skill_card_number);
      formData.append('skill_card_scheme_name', skill_card_scheme_name);
      formData.append('skill_card_exp', skill_card_exp);
      formData.append('skill_card_surname', skill_card_surname);
      
      return await this.$axios.post(
        '/api/worker-registration/skill/photos',
        formData,
        {'Content-type': 'multipart/form-data'}
      );
    });
  },
  async setBasicInfo({commit, state}, data) {
    return await processApiForm({commit}, async () => {
      return await this.$axios.post('/api/worker-registration/basic-info', data);
    });
  },
  async setProfilePhoto({commit, state}, {profile_photo}) {
    return await processApiForm({commit}, async () => {
      //TODO: currently for demo we load images as base64 and here we need to reformat them to blob. Probably better to change format to accept already in blob/binary from react native
      const photoAsBlob = await fetch(profile_photo).then(res => res.blob());
      const formData = new FormData();
      formData.append('profile_photo', photoAsBlob);
      return await this.$axios.post(
        '/api/worker-registration/basic-info/photo',
        formData,
        {'Content-type': 'multipart/form-data'}
      );
    });
  },
  async setNINumber({commit, state}, info) {
    return await processApiForm({commit}, async () => {
      return await this.$axios.post('/api/worker-registration/ni-number', info);
    });
  },
  async setMedicalQuestionnaireAnswers({commit, state}, data) {
    return await processApiForm({commit}, async () => {
      return await this.$axios.post('/api/worker-registration/medical-questionnaire', {
        results: data.results
      });
    });
  },
  async setEmergencyContact({commit, state}, data) {
    return await processApiForm({commit}, async () => {
      return await this.$axios.post('/api/worker-registration/emergency-contact', data);
    });
  },
};
