import moment from 'moment-timezone'
import Vue from 'vue'
const timezone = process.env.TIMEZONE

const momentMixin = {
  computed: {
    duration() {
      return moment.duration
    },
  },
  methods: {
    moment() {
      // DEPRECATED
      // this function is handling unix timestamp INCORRECTLY
      return moment();
    },
    getTime(time) {
      if ('string' === typeof time) {
        time = time.split(':');
        if (time.length < 2) {
          return '-';
        }

        return time[0] + ':' + time[1];
      }
    },

    toMoment(date = new Date(), unix = true) {
      if (unix) {
        return moment.unix(date).tz(timezone)
      } else {
        return moment(date).tz(timezone)
      }
    },

    getDate(date = new Date(), format = 'DD/MM/YYYY', unix = true) {
        return this.toMoment(date, unix).format(format)
    },

    formatDate (date, format = 'DD/MM/YYYY') {
      // works better at ios
      return this.getDate(date, format, false)
    },

    getDurationShort(dur, secs = false) {
      let hours
      let mo 
      if (typeof dur === 'object') {
        mo = dur
        hours = parseInt(mo.asHours())
      } else {
        mo = this.duration(dur, 'hours')
        hours = parseInt(dur)
      } 
      return ("0" + hours).slice(-2) + ':' + ("0" + mo.minutes()).slice(-2) + (secs ? ':' + (("0" + mo.seconds()).slice(-3)) : '')
    },

    getDuration(dur, secs = false) {
      let hours
      let mo 
      if (typeof dur === 'object') {
        mo = dur
        hours = parseInt(mo.asHours())
      } else {
        mo = this.duration(dur, 'hours')
        hours = parseInt(dur)
      } 
      return ("0" + hours).slice(-2) + 'h ' + ("0" + mo.minutes()).slice(-2) + 'm ' + (secs ? ("0" + mo.seconds()).slice(-2) + 's' : '')
    },
  },
}

const UiDuration = Vue.component('UiDuration', {
  mixins: [momentMixin],
  props: { 
    dur: {
      type: [String, Number]
    },
    compact: {
      type: Boolean,
      default: false
    },
    insteadOfZero: {
      type: String,
      default: null
    },
    showTooltip: {
      type: Boolean,
      default: true
    },
  },
  render: function (h) {
      if (this.dur) {
        const dur = this.duration(this.dur, 'hours')
        if (dur.asMinutes() > 0) {
          return (
            <span>
              { this.showTooltip && 
                <ui-tooltip>
                  { this.getDuration(this.dur, true) }
                </ui-tooltip>
              }
              { this.compact ? this.getDurationShort(dur) : this.getDuration(dur) }
            </span>
          )        
        } else {
          return <span>{this.insteadOfZero}</span>
        }
      } else {
        return <span>{this.insteadOfZero}</span>
      }
  }
})

export default {
  mixins: [momentMixin],
  components: {
    UiDuration
  }
}
