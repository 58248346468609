export default {
  clearTimesheets: (state) => {
    state.timesheets = []
  },
  
  setTimesheets: (state, {dataSlotName, timesheets, replace}) => {

    const currentTimesheets = state.timesheets[dataSlotName] ?? []
    const newTimesheets = 
      replace 
        ? timesheets
        : [].concat(currentTimesheets).concat(timesheets)

    state.timesheets = {
      ...state.timesheets,
      [dataSlotName]: newTimesheets
    }
  },

  updateExistingTimesheets: (state, {dataSlotName, timesheets}) => {

    const currentTimesheets = state.timesheets[dataSlotName] ?? []
    timesheets.forEach( ts => {
      const tsIndex = currentTimesheets.findIndex( nextTs => nextTs.id === ts.id)
      currentTimesheets.splice(tsIndex, 1, ts)
    })
  },

  setTimesheetsMeta: (state, meta) => {
    state.timesheetsMeta = meta;
  },

  setTimesheet: (state, timesheet) => {
    state.timesheet = timesheet;
  },

  setPendingTimesheets: (state, isPending = true) => {
    state.isTimesheetsPending = isPending;
  },

  setTimesheetMessage: (state, msgs) => {
    state.timesheet.messages = msgs;
  },

  toggleData: state => {
    state.hasData = true;
  },

  setFiltersState: (state, {filtersName, filters}) => {
    state.filtersState = {
      ...state.filtersState,
      [filtersName]: {...filters}
    }
  },

  setFilters: (state, filters) => {
    state.filters = filters;
  },

  setSummaryDataForExisting: (state, {rangeIndex, summary}) => {
    state.filters.range.splice(rangeIndex, 1, summary)
  },

  setMoreFilters: (state, filters) => {
    state.filters.range = [].concat(state.filters.range ?? [], filters.range ?? [])
  },
  
  setCheckinsOverlay: (state, {checkins, status}) => {
    if (typeof status === 'undefined') {
      //Clear
      state.checkinsOverlay = {}
    } else {
      //Append
      state.checkinsOverlay = {
        ...state.checkinsOverlay,
        [status]: checkins ?? []
      }
  
    }
  },

  setDateSiteIds: (state, siteIds) => {
    state.dateSiteIds = siteIds
  },

  setError: (state, error) => {
    state.error = error;
  },

  setExcelUrl: (state, url) => {
    state.excelUrl = url;
  },
};
