/**
 * Main steps
 */
export const WRS_PHONE_VERIFICATION_STEP_ID = 'phone-verification-step';
export const WRS_NOTIFICATIONS_PERMISSION_STEP_ID = 'notifications-permission';
export const WRS_ONFIDO_VERIFICATION_STEP_ID = 'onfido-verification-step';
export const WRS_NI_NUMBER_STEP_ID = 'ni-number-step';
export const WRS_SKILL_PHOTOS_STEP_ID = 'skill-photos-step';
export const WRS_MEDICAL_QUESTIONNAIRE_STEP_ID = 'medical-questionnaire-step';
export const WRS_PERSONAL_INFO_STEP_ID = 'personal-info-step';
export const WRS_EMERGENCY_CONTACT_STEP_ID = 'emergency-contact-step';

/** 100% and FINISHED are different as: 100% is a code from backend that registration flow doesn't need to be shown. FINISHED is used to show on a registration flow after user succeeds with last step **/
export const WRS_FINISHED_STEP_ID = 'finished-step';
export const WRS_COMPLETED = '100%';

/**
 * Substeps
 */

/** WRS_PHONE_VERIFICATION_STEP_ID substeps **/
export const PHONE_FORM_SUBSTEP_ID = `${WRS_PHONE_VERIFICATION_STEP_ID}-phone-form`;
export const PASSWORD_FORM_SUBSTEP_ID = `${WRS_PHONE_VERIFICATION_STEP_ID}-password-form`;
export const SET_PASSWORD_FORM_SUBSTEP_ID = `${WRS_PHONE_VERIFICATION_STEP_ID}-set-password-form`;
export const CODE_VERIFY_SUBSTEP_ID = `${WRS_PHONE_VERIFICATION_STEP_ID}-code-verify`;

/** WRS_ONFIDO_VERIFICATION_STEP_ID substeps **/
export const START_REGISTRATION_SUBSTEP_ID = `${WRS_ONFIDO_VERIFICATION_STEP_ID}-start-registration`;
export const VERIFY_YOUR_IDENTITY_SUBSTEP_ID = `${WRS_ONFIDO_VERIFICATION_STEP_ID}-verify-your-identity`;
export const ONFIDO_RESULT_LOADER = `${WRS_ONFIDO_VERIFICATION_STEP_ID}-onfido-result-loader`;

/** WRS_PERSONAL_INFO_STEP_ID **/
export const PERSONAL_INFO_FORM_SUBSTEP_ID = `${WRS_PERSONAL_INFO_STEP_ID}-form`;
export const PERSONAL_INFO_PROFILE_PHOTO_SUBSTEP_ID = `${WRS_PERSONAL_INFO_STEP_ID}-profile-photo`;

/**
 Other
 **/

export const SKILL_CARD_SIDE_FRONT = 'front';
export const SKILL_CARD_SIDE_BACK = 'back';
