<template>
    <div>
        <nuxt/>
        <LoaderComponent/>
    </div>
</template>

<script>
import LoaderComponent from '../components/LoaderComponent.vue';

export default {
    data() {
        return {}
    },
    components: {
        LoaderComponent
    },
    mounted() {
        this.$store.dispatch('dict/init');
    },
    computed: {
        hasDict() {
            return this.$store.getters['dict/hasData']
        }
    }
}
</script>

<style lang="scss">
@import '~assets/scss/global_vars';

</style>
