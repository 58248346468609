export default () => ({
  timesheets: {},
  timesheetsMeta: null,
  timesheet: null,
  excelUrl:null,
  filters: null,
  filtersState: {},
  hasData: false,
  error: '',
  isTimesheetsPending: false,
  dateSiteIds: [],
  checkinsOverlay: {}
});
